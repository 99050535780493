<template>
    <v-dialog
        :title="$t('mainNavbar.setting.webhooks.popup.title')"
        width="30%"
        :show.sync="dialogVisible"
        class="payload-dialog"
    >
        <Markdown :contents="markdownContents" />

        <div slot="footer">
            <el-button ref="clipboard" size="small" type="primary">
                <i class="fa fa-copy" /> {{ $t('mainNavbar.setting.webhooks.popup.copy') }}
            </el-button>
        </div>
    </v-dialog>
</template>

<script>
    import Clipboard from 'clipboard'
    import Markdown from '~/components/contents/Markdown.vue'

    export default {

        components: {
            Markdown,
        },

        props: {
            payload: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                dialogVisible: false,
                $clipboard: null,
            }
        },

        computed: {
            markdownContents() {
                return `\`\`\`json \n${this.payload}\n\`\`\``
            },
        },

        methods: {
            open() {
                this.$nextTick(() => {
                    if (this.$refs.clipboard) {
                        this.$clipboard = new Clipboard(this.$refs.clipboard.$el, {
                            text: () => this.payload,
                        })
                        this.$clipboard.on('success', () => {
                            this.$message.success('Copied successfully!')
                        })
                    }
                })
                this.dialogVisible = true
            },

            close() {
                this.dialogVisible = false
            },
        },
    }
</script>
<style lang='scss'>
    .payload-dialog {
        .dialog{
            &__body{
                padding-bottom: 0px
            }
            &__footer{
                padding-top: 0px
            }
        }
    }
</style>
