<template>
    <el-select
        v-model="value"
        :loading="loading"
        :remote-method="fetchData"
        :placeholder="placeholder"
        filterable
        clearable
        remote
        @change="$emit('change', $event)"
    >
        <el-option
            v-for="(item, index) in data"
            :key="index"
            :value="item.slug"
            :label="item.name"
        />
    </el-select>
</template>

<script>
    import { getTags } from '~/api/users'

    export default {
        props: {
            technical: {
                type: Boolean,
                default: true,
            },
            placeholder: {
                type: String,
                default: 'Following / Experience Tags',
            },
        },

        data() {
            return {
                value: this.$route.query.tag,
                loading: false,
                data: [],
            }
        },

        async mounted() {
            await this.fetchData()
        },

        methods: {
            async fetchData(q) {
                try {
                    this.loading = true

                    const { data } = await getTags({ q, technical: this.technical })

                    this.data = data
                } catch (e) {
                    this.data = []
                } finally {
                    this.loading = false
                }
            },
        },
    }
</script>
