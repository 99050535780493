<template>
    <v-dialog
        :show.sync="show"
        animation="zoom-out"
        size="small"
    >
        <div>
            <span class="text-bold">{{ $t(titleAction) }}</span>
            <el-form
                ref="form"
                label-position="top"
                size="mini"
                class="mt-1"
                :model="form"
                :rules="rules"
                @submit.native.prevent="submit"
            >
                <el-form-item :label="$t('popUpSocialShare.content')" prop="content" :error="serverErrors.message">
                    <el-input
                        v-model="form.content"
                        :autosize="{ minRows: 15 }"
                        type="textarea"
                        :placeholder="$t('popUpSocialShare.placeholderContent')"
                    />
                </el-form-item>
                <el-form-item :error="serverErrors.rank_score" prop="rankScore">
                    <span slot="label">
                        {{ $t('popUpSocialShare.rankingScore') }}
                        <el-popover
                            placement="bottom-start"
                            width="300"
                            trigger="click"
                        >
                            <el-button
                                slot="reference"
                                type="text"
                                icon="el-icon-question"
                                style="font-size: 1.2rem"
                            />
                            <p class="word-break">
                                {{ $t('popUpSocialShare.detailRankScore.content1') }}
                                <b>{{ $t('popUpSocialShare.detailRankScore.content2') }}</b>
                                {{ $t('popUpSocialShare.detailRankScore.content3') }}
                            </p>
                            <p class="word-break">
                                {{ $t('popUpSocialShare.detailRankScore.content4') }}
                            </p>
                        </el-popover>
                    </span>
                    <el-input
                        v-model="form.rankScore"
                        :placeholder="$t('popUpSocialShare.placeholderRankScore')"
                    />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('popUpSocialShare.cancel') }}</el-button>
            <el-button type="primary" @click="submit($refs.form, addShare)">{{ $t('popUpSocialShare.add') }}</el-button>
        </span>
    </v-dialog>
</template>

<script>
    import popupForm from '~/components/mixins/form'

    export default {

        mixins: [popupForm],
        props: {
            rankScore: {
                type: Number,
                default: 0.8,
            },
            title: {
                type: String,
                default: 'popUpSocialShare.titleDefault',
            },
            summary: {
                type: String,
            },
        },

        data() {
            return {
                rules: {
                    content: { required: true, message: this.$t('popUpSocialShare.validations.requiredContent') },
                    rankScore: { required: true, message: this.$t('popUpSocialShare.validations.requiredRankScore') },
                },
                form: {
                    content: this.summary ? this.summary : 'Tìm đọc bài viết dưới đây để học hỏi thêm những kiến thức mới cùng Viblo và ủng hộ các tác giả.',
                    rankScore: this.rankScore,
                },
                serverErrors: {},
                titleAction: this.title,
                show: false,
            }
        },

        watch: {
            content(nextValue) {
                this.form.content = nextValue
                this.form.rankScore = this.rankScore
            },
        },

        methods: {
            open(data = null) {
                if (data) {
                    this.form.content = data.preview_message
                    this.form.rankScore = data.rank_score
                    this.form.id = data.id
                }

                this.show = true
            },

            close() {
                this.show = false
            },

            addShare(value) {
                return this.$emit('addShare', value)
            },
        },
    }
</script>
