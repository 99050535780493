<template>
    <div>
        <el-table
            ref="filterTable"
            :data="socialShares"
            @filter-change="onFilter"
        >
            <el-table-column type="expand">
                <ExpandItem slot-scope="props" class="expand-item" :social-share="props.row" />
            </el-table-column>

            <el-table-column
                :show-overflow-tooltip="true"
                :label="$t('myContent.socialShare.title')"
                width="400"
            >
                <template slot-scope="scope">
                    <shareable-title :shareable="scope.row.shareable" :type="scope.row.type" />
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('myContent.socialShare.creator')"
                width="200"
            >
                <template slot-scope="props">
                    <creator-avatar :creator="props.row.creator" />
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('myContent.socialShare.type')"
                prop="type"
                column-key="type"
                class-name="text-capitalize"
                :filters="entityTypes"
                :filtered-value="activeType"
                :filter-multiple="false"
            />

            <el-table-column
                :label="$t('myContent.socialShare.rankScore')"
                prop="rank_score"
                width="150"
            />

            <el-table-column
                :label="$t('myContent.socialShare.createdAt')"
                prop="created_at"
                width="200"
                :formatter="formatter"
            />

            <!-- When changing the width, it is necessary to change the width in the expand-item class -->
            <el-table-column
                fixed="right"
                :label="$t('myContent.socialShare.actions')"
                width="150"
            >
                <template slot-scope="scope">
                    <div>
                        <el-button
                            size="mini"
                            @click="handleEdit(scope.row)"
                        >
                            <i class="el-icon-edit" />
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                        >
                            <i class="el-icon-remove" />
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.last_page"
            :page-count="pagination.total"
            :page-size="pagination.per_page"
        />

        <add-social-share-popup ref="socialShareDialog" @addShare="updatePostShare" />
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import _findIndex from 'lodash/findIndex'
    import ExpandItem from '~/components/social-shares/ExpandItem.vue'
    import Pagination from '~/components/Pagination.vue'
    import CreatorAvatar from '~/components/social-shares/CreatorAvatar.vue'
    import ShareableTitle from '~/components/social-shares/ShareableTitle.vue'
    import {
        update, destroy,
    } from '~/api/social-shares'

    import { humanizeTime } from '~/filters/dates'
    import AddSocialSharePopup from '~/components/dialogs/AddSocialShare.vue'

    const entityTypes = [
        {
            text: 'Post',
            value: 'post',
        },
        {
            text: 'Question',
            value: 'question',
        },
        {
            text: 'Series',
            value: 'series',
        },
    ]

    export default {
        components: {
            ExpandItem,
            Pagination,
            CreatorAvatar,
            ShareableTitle,
            AddSocialSharePopup,
        },

        watchQuery: true,

        props: {
            socialShares: {
                type: Array,
            },
            pagination: {
                type: Object,
            },
        },

        data() {
            return {
                entityTypes,
            }
        },

        computed: {
            activeType() {
                return this.$route.query.type ? [this.$route.query.type] : undefined
            },
        },

        methods: {
            onFilter(filter) {
                this.$router.push({

                    query: _assign({}, this.$route.query, {
                        status: 'new',
                        type: filter.type ? filter.type[0] : this.$route.query.type,
                        page: 1,
                    }),
                })
            },

            updatePostShare(value) {
                const message = value.content
                const rankSscore = value.rankScore
                this.updateContent(value, {
                    message,
                    rank_score: rankSscore,
                })
            },

            formatter(row) {
                return `${humanizeTime(row.created_at)}`
            },

            handleEdit(socialShare) {
                this.$refs.socialShareDialog.open(socialShare)
            },

            async updateContent(socialShare, message) {
                try {
                    await update(socialShare.id, message)
                    this.$message.success(this.$t('popUpSocialShare.updateSuccess'))
                    this.$refs.socialShareDialog.close()
                } catch (e) {
                    this.$message.error(this.$t('popUpSocialShare.updateError'))
                    this.$refs.socialShareDialog.close()
                }
            },


            handleDelete(socialShare) {
                this.$confirm(this.$t('popUpSocialShare.handleDelete.content'), this.$t('popUpSocialShare.handleDelete.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => this.delete(socialShare))
            },

            async delete(socialShare) {
                try {
                    await destroy(socialShare.id)
                    this.$message.success(this.$t('popUpSocialShare.handleDelete.success'))
                    const index = _findIndex(this.socialShares, { id: socialShare.id })
                    this.socialShares.splice(index, 1)
                } catch (e) {
                    this.$message.error(this.$t('popUpSocialShare.handleDelete.error'))
                }
            },
        },
    }
</script>

<style lang="scss">
    .expand-item {
        width: calc(100% - 150px);
    }
</style>
