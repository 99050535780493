<template>
    <div>
        <nuxt-link :to="url" class="link">
            {{ shareable.title }}
        </nuxt-link>
    </div>
</template>

<script>
    import {
        post as toPost,
        question as toQuestion,
        series as toSeries,
    } from '~/utils/url'

    export default {
        props: {
            shareable: {
                type: Object,
                required: true,
            },

            type: {
                type: String,
                required: true,
            },
        },

        computed: {
            url() {
                if (this.type === 'Post') {
                    return toPost(this.shareable)
                }
                if (this.type === 'Question') {
                    return toQuestion(this.shareable)
                }
                if (this.type === 'Series') {
                    return toSeries(this.shareable)
                }

                return ''
            },
        },
    }
</script>
