// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container pt-3 pb-1">
        <el-dropdown trigger="click" class="mb-1">
            <span class="cursor-pointer">
                {{ $t('notification.options.label') }} <i class="el-icon-arrow-down" />
            </span>

            <el-dropdown-menu slot="dropdown">
                <template v-if="!empty">
                    <el-dropdown-item @click.native="clear">
                        <i class="fa fa-check-square" aria-hidden="true" /> {{ $t('notification.options.markAsRead') }}
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="confirmDelete">
                        <i class="fa fa-minus-square" aria-hidden="true" /> {{ $t('notification.options.deleteAll') }}
                    </el-dropdown-item>
                </template>

                <el-dropdown-item>
                    <nuxt-link to="/settings/notifications" class="link link--plain">
                        <i class="fa fa-cog" aria-hidden="true" /> {{ $t('notification.options.settings') }}
                    </nuxt-link>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <el-alert
            :closable="false"
            type="info"
            title=""
            class="mb-1"
            center
        >
            <i class="fa fa-bell-slash-o" aria-hidden="true" /> {{ $t('notification.noNew') }}.
        </el-alert>

        <div class="section-title-line">
            <h4 class="font-weight-bold">
                {{ $t('notification.label') }}
            </h4>
            <hr class="filler-line">
        </div>

        <NotificationPlaceholder v-if="loadNoti" />

        <div v-else>
            <notification-list :notifications="notifications" @item-click="setRead($event)" />

            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="onInfinite">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import Container from '~/components/notifications/Container'
    import NotificationList from '~/components/notifications/List.vue'
    import NotificationPlaceholder from '~/containers/notifications/index.vue'
    import { loginWithSSR } from '~/utils/login'
    import { handlePageError } from '~/utils/pages'

    export default {

        components: {
            InfiniteLoading,
            NotificationList,
            NotificationPlaceholder,
        },
        mixins: [Container],

        middleware: ['auth'],

        computed: {
            ...mapState('notifications', ['loadNoti']),

            empty() {
                return this.notifications.length === 0
            },
        },

        async fetch({
            store, req, redirect, error,
        }) {
            try {
                return await store.dispatch('notifications/fetch')
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },

        mounted() {
            this.clear()
        },

        methods: {
            ...mapActions('notifications', ['deleteAll']),

            onInfinite($state) {
                this.fetchMore().then(() => {
                    if (this.hasMore()) {
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
            },

            confirmDelete() {
                this.$confirm(this.$t('deleteNotification.content'), this.$t('deleteNotification.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => this.deleteAll())
            },
        },
    }
</script>
