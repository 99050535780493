<template>
    <div>
        <div class="d-flex flex-wrap justify-content-center pb-1">
            <DatePicker
                :show-button="false"
                :to="dateRange[1]"
                :from="dateRange[0]"
                class="filterDate"
            />

            <TechnicalTag
                :technical="false"
                class="filerTag d-flex align-items-center flex-grow-1"
                :placeholder="$t('myContent.search.tag')"
                @change="filter('tag', $event)"
            />

            <Search
                query-param="q"
                class="Search flex-grow-1"
                :placeholder="$t('myContent.search.search')"
            />
        </div>

        <el-alert
            v-if="errors"
            :title="errors.message"
            type="error"
            class="mb-1"
            show-icon
        >
            <ul>
                <li v-for="(error, index) in errors" :key="index">
                    {{ error[0] }}
                </li>
            </ul>
        </el-alert>

        <ListPostsScheduled :social-shares="socialShares" :pagination="pagination" />
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import { mapGetters } from 'vuex'
    import { get } from '~/api/social-shares'
    import { toFacebook } from '~/utils/url'
    import { handlePageError } from '~/utils/pages'

    import DatePicker from '~/components/widgets/DatePicker.vue'
    import Search from '~/components/searchPage/SearchInMyContentPage.vue'
    import TechnicalTag from '~/components/searchPage/TechnicalTag.vue'
    import ListPostsScheduled from '~/components/shared/ListPostsScheduled.vue'
    import { loginWithSSR } from '~/utils/login'

    export default {
        middleware: 'auth',

        components: {
            DatePicker,
            Search,
            TechnicalTag,
            ListPostsScheduled,
        },

        data() {
            const tableActions = [
                {
                    icon: 'el-item-edit',
                    action: this.edit,
                },
            ]
            const from = this.$route.query.from
            const to = this.$route.query.to
            const dateRange = [from, to]

            return {
                tableActions,
                dateRange,
            }
        },

        computed: {
            ...mapGetters('auth', ['hasRole', 'authenticated', 'isSocialMediaEditor']),
        },

        watchQuery: true,

        async asyncData({
            store, query, route, error, req, redirect,
        }) {
            try {
                const status = 'scheduled'
                const type = route.query.type

                const data = await get({
                    status,
                    type,
                    limit: store.state.settings.perPage,
                    page: query.page || 1,
                    ...query,
                })

                return {
                    socialShares: data.data,
                    pagination: data.meta,
                    errors: null,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                if (e.response && e.response.status === 422) {
                    return { socialShares: [], pagination: [], errors: e.response.data.errors }
                }

                return handlePageError(error, e)
            }
        },

        methods: {
            filterHandle(value, row, column) {
                const property = column.property

                return row[property] === value
            },

            toFanpage(socialShare) {
                return toFacebook(socialShare.id)
            },

            filter(field, value) {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        [field]: value,
                        page: 1,
                    }),
                })
            },
        },

        meta: {
            title: ('myContent.title.sharesScheduled'),
        },
    }
</script>
