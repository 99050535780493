// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <SettingLayout :page-title="pageTitle">
        <SettingMenu slot="menu" />
    </SettingLayout>
</template>

<script>
    import SettingLayout from '~/components/settings/Layout.vue'
    import SettingMenu from '~/components/auth/SettingMenu.vue'
    import pageTitle from '~/components/mixins/pageTitle'

    export default {
        middleware: 'auth',

        scrollToTop: true,

        components: {
            SettingLayout,
            SettingMenu,
        },

        mixins: [
            pageTitle({ defaultTitle: 'Settings' }),
        ],
    }
</script>
