// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="webhook-settings">
        <SettingsItem>
            <template slot="title">
                {{ $t('mainNavbar.setting.webhooks.newPost') }}
                <a href="/p/RnB5pxDd5PG" target="_blank">
                    <i class="fa fa-question-circle" />
                </a>
            </template>
            <template slot="description">
                {{ $t('mainNavbar.setting.webhooks.firstDetail') }}
                <span class="link" @click="getPayloadStructure">{{ $t('mainNavbar.setting.webhooks.link') }}</span>
                {{ $t('mainNavbar.setting.webhooks.lastDetail') }}
            </template>
            <el-switch
                slot="button"
                :value="webhook.active"
                @change="toggleWebhook()"
            />
        </SettingsItem>

        <Form :show="webhook.active" :webhook="webhook" @update="update" />

        <PayloadStructure ref="dailogStructurePayload" :payload="payloadStructure" />
    </div>
</template>

<script>
    import Form from '~/components/webhooks/form.vue'
    import SettingsItem from '~/components/settings/SettingsItem.vue'
    import PayloadStructure from '~/components/dialogs/PayloadStructure.vue'
    import { getWebhook, toggleActiveWebhook, getPayloadStructure } from '~/api/webhooks'

    export default {
        components: {
            SettingsItem,
            Form,
            PayloadStructure,
        },

        data() {
            return {
                webhook: {
                    url: '',
                    options: {
                        secure_token_header: '',
                        secure_token: '',
                        use_basic_auth: false,
                        auth_username: '',
                        auth_password: '',
                    },
                    status_code: '',
                    active: false,
                    name: 'new_post',
                },
                payloadStructure: '',
            }
        },

        async asyncData() {
            const { data } = await getWebhook({ name: 'new_post' }).then(res => res.data)
            if (data) {
                return { webhook: data }
            }

            return {}
        },

        methods: {
            async toggleWebhook() {
                try {
                    if (this.checkExitWebhook()) {
                        await toggleActiveWebhook(this.webhook.id)
                    }
                    this.webhook.active = !this.webhook.active
                } catch (error) {
                    this.$message.error(this.$t('settings.error'))
                }
            },

            update(data) {
                this.webhook = data
            },

            checkExitWebhook() {
                return !!this.webhook.id
            },

            async getPayloadStructure() {
                const { data } = await getPayloadStructure('new_post').then(res => res.data)
                this.payloadStructure = JSON.stringify(data, null, 4)
                this.$refs.dailogStructurePayload.open()
            },
        },

        // the page title will be parse from `meta` options by `~/components/mixins/pageTitle`
        meta: {
            title: 'mainNavbar.setting.webhooks.title',
        },
    }
</script>

<style scoped lang='scss'>
    .webhook-settings {
        .settings-item {
            &:not(:last-child) {
                padding-bottom: 0;
                margin-bottom: 1rem;
                border-bottom: none !important;
            }
        }
    }
    .link {
        cursor: pointer;
        color: #5488c7;
        &:hover {
            text-decoration: underline
        }
    }
</style>
