<template>
    <div v-if="creator">
        <Avatar :image="creator.avatar" size="sm" class="d-inline-block mr-05" />
        {{ creator.name }}
    </div>

    <div v-else class="d-flex align-items-center">
        <img class="avatar avatar--sm d-inline-block mr-05" src="/images/bot-ai.png">
        Viblo-Bot
    </div>
</template>

<script>
    import Avatar from '~/components/shared/Avatar.vue'

    export default {
        components: {
            Avatar,
        },

        props: {
            creator: {
                type: Object,
                default: null,
            },
        },
    }
</script>

<style lang="scss" scoped>
.avatar {
    border: 1px solid #eee;
    border-radius: 50%;
}
</style>
