// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="user-series">
        <div class="d-flex flex-wrap justify-content-center pb-1">
            <DatePicker
                :show-button="false"
                :from="dateRange[0]"
                :to="dateRange[1]"
                class="filterDate"
            />

            <TechnicalTag
                :technical="false"
                class="filerTag d-flex align-items-center flex-grow-1"
                :placeholder="$t('myContent.search.tag')"
                @change="filter('tag', $event)"
            />

            <Search
                query-param="q"
                class="Search flex-grow-1"
                :placeholder="$t('myContent.search.search')"
            />
        </div>


        <empty v-if="posts.length <= 0">
            <p> {{ $t('empty') }} </p>
        </empty>

        <div v-else>
            <ReviewPost
                :posts="posts"
            />
        </div>

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.last_page"
            :page-count="pagination.total"
            :page-size="pagination.per_page"
        />
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import { getPostsMarkSpam } from '~/api/posts'
    import Pagination from '~/components/Pagination.vue'
    import DatePicker from '~/components/widgets/DatePicker.vue'
    import Search from '~/components/searchPage/SearchInMyContentPage.vue'
    import TechnicalTag from '~/components/searchPage/TechnicalTag.vue'
    import Empty from '~/components/shared/Empty.vue'
    import ReviewPost from '~/components/posts/ListPostsSpam.vue'
    import { loginWithSSR } from '~/utils/login'

    export default {
        components: {
            Pagination,
            DatePicker,
            Search,
            TechnicalTag,
            Empty,
            ReviewPost,
        },

        data() {
            const from = this.$route.query.from
            const to = this.$route.query.to
            const dateRange = [from, to]

            return {
                dateRange,
            }
        },

        async asyncData({
            store, redirect, query, req,
        }) {
            try {
                const posts = await getPostsMarkSpam(query)
                    .then(response => response.data)

                return {
                    posts: posts.data,
                    pagination: posts.meta,
                    errors: null,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                const errors = e.response.data.errors

                return {
                    posts: [],
                    pagination: [],
                    errors,
                }
            }
        },

        methods: {
            filter(field, value) {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        [field]: value,
                        page: 1,
                    }),
                })
            },
        },

        watchQuery: true,

        meta: {
            title: ('myContent.title.postsSpam'),
        },
    }
</script>
