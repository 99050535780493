<template>
    <div v-if="show">
        <ElForm
            ref="form"
            :model="form"
            :rules="validations"
            size="medium"
            class="error-message--top-end"
        >
            <ElFormItem
                prop="url"
                :label="$t('mainNavbar.setting.webhooks.form.targetURL')"
                :error="serverErrors.url"
            >
                <ElInput v-model="form.url" name="url" @change="text => form.url = text.trim()" />
            </ElFormItem>
            <ElFormItem
                prop="secret"
                :label="$t('mainNavbar.setting.webhooks.form.secret')"
                :error="serverErrors.secret"
            >
                <ElInput
                    v-model="form.options.secret"
                    :type="inputTypeSecret"
                    :error="serverErrors['options.secret']"
                    name="secret"
                    @change="text => form.secret = text.trim()"
                >
                    <i
                        v-if="form.options.secret"
                        slot="suffix"
                        :style="stylePreview"
                        class="el-icon-view el-input__icon"
                        @click="previewSecret"
                    />
                </ElInput>
            </ElFormItem>
            <div class="d-flex flex-wrap">
                <ElFormItem
                    prop="secure_token_header"
                    :label="$t('mainNavbar.setting.webhooks.form.tokenHeader')"
                    :error="serverErrors['options.secure_token_header']"
                    class="mr-1"
                >
                    <ElInput
                        v-model="form.options.secure_token_header"
                        name="secure_token_header"
                        placeholder="X-API-KEY"
                        @change="text => form.options.secure_token_header = text.trim()"
                    />
                    <div slot="error" slot-scope="error" class="error-token-header">
                        {{ error.error }}
                    </div>
                </ElFormItem>
                <ElFormItem
                    prop="secure_token"
                    :label="$t('mainNavbar.setting.webhooks.form.token')"
                    :error="serverErrors['options.secure_token']"
                    class="flex-grow-1"
                >
                    <ElInput
                        v-model="form.options.secure_token"
                        name="secure_token"
                        @change="text => form.options.secure_token = text.trim()"
                    />
                </ElFormItem>
            </div>

            <ElFormItem
                prop="use_basic_auth"
                :label="$t('mainNavbar.setting.webhooks.form.basicAuth')"
                class="mt-1"
                :error="serverErrors['options.use_basic_auth']"
            >
                <el-switch
                    v-model="form.options.use_basic_auth"
                />
            </ElFormItem>

            <div v-show="form.options.use_basic_auth">
                <ElFormItem
                    prop="auth_username"
                    :label="$t('mainNavbar.setting.webhooks.form.username')"
                    :error="serverErrors['options.auth_username']"
                >
                    <ElInput
                        v-model="form.options.auth_username"
                        name="auth_username"
                        :placeholder="$t('mainNavbar.setting.webhooks.form.username')"
                        @change="text => form.options.auth_username = text.trim()"
                    />
                </ElFormItem>

                <ElFormItem
                    prop="auth_password"
                    :label="$t('mainNavbar.setting.webhooks.form.password')"
                    :error="serverErrors['options.auth_password']"
                >
                    <ElInput
                        v-model="form.options.auth_password"
                        type="password"
                        name="auth_password"
                        :placeholder="$t('mainNavbar.setting.webhooks.form.password')"
                        @change="text => form.options.password = text.trim()"
                    />
                </ElFormItem>
            </div>

            <el-form-item>
                <el-button
                    type="primary"
                    class="pull-right"
                    size="small"
                    :loading="processing"
                    @click="submit($refs.form, submitForm)"
                >
                    {{ $t('mainNavbar.setting.webhooks.form.update') }}
                </el-button>
                <el-button
                    v-if="webhook.id"
                    type="warning"
                    class="pull-right mr-1"
                    size="small"
                    :loading="processingTest"
                    @click="submit($refs.form, updateAndTest)"
                >
                    <span v-if="!formChanged">
                        {{ $t('mainNavbar.setting.webhooks.test') }}
                    </span>
                    <span v-else>
                        {{ $t('mainNavbar.setting.webhooks.updateAndTest') }}
                    </span>
                </el-button>
            </el-form-item>
        </ElForm>
    </div>
</template>

<script>
    import _pick from 'lodash/pick'
    import { updateWebhook, testWebhook, updateAndTestWebhook } from '~/api/webhooks'
    import formMixin from '~/utils/form/mixin/'

    export default {

        mixins: [formMixin],
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            webhook: {
                type: Object,
                required: true,
            },
        },

        data() {
            const form = _pick(this.webhook, ['url', 'options', 'name', 'status'])
            const validationI18n = 'mainNavbar.setting.webhooks.validations'

            const validations = {
                url: [
                    { required: true, message: this.$t(`${validationI18n}.url.required`), trigger: 'change' },
                    { type: 'url', message: this.$t(`${validationI18n}.url.type`), trigger: 'change' },
                    { max: 255, message: this.$t(`${validationI18n}.url.max`), trigger: 'change' },
                ],
                secure_token_header: [
                    { max: 255, message: this.$t(`${validationI18n}.secureTokenHeader.max`), trigger: 'change' },
                ],
                secure_token: [
                    { max: 255, message: this.$t(`${validationI18n}.secureToken.max`), trigger: 'change' },
                ],
                secret: [
                    { max: 255, message: this.$t(`${validationI18n}.secret.max`), trigger: 'change' },
                ],
            }

            const inputTypeSecret = 'password'

            return {
                form, validations, formChanged: false, processingTest: false, inputTypeSecret,
            }
        },

        computed: {
            stylePreview() {
                return {
                    cursor: 'pointer',
                    color: this.inputTypeSecret === 'text' ? '#5488c7' : '',
                }
            },
        },

        watch: {
            form: {
                handler() {
                    this.formChanged = true
                },
                deep: true,
            },
        },

        methods: {
            async submitForm() {
                this.processing = true
                try {
                    const body = this.getBody()
                    const { data } = await updateWebhook(body).then(res => res.data)
                    this.$message.success(this.$t('mainNavbar.setting.webhooks.form.webhookUpdated'))
                    this.$emit('update', data)
                    this.formChanged = false
                } catch (error) {
                    this.handleError(error)
                } finally {
                    this.processing = false
                }
            },

            async updateAndTest() {
                this.processingTest = true
                try {
                    if (!this.formChanged) {
                        await testWebhook(this.webhook.id)
                        this.$message.success(this.$t('mainNavbar.setting.webhooks.form.checkWebhook', { url: this.form.url }))
                        return
                    }
                    const body = this.getBody()
                    const { data } = await updateAndTestWebhook(this.webhook.id, body).then(res => res.data)
                    this.$message.success(`Update and sent a request to ${this.form.url}. Please check it!`)
                    this.$emit('update', data)
                    this.formChanged = false
                } catch (error) {
                    this.handleError(error)
                } finally {
                    this.processingTest = false
                }
            },

            previewSecret() {
                this.inputTypeSecret = this.inputTypeSecret === 'password' ? 'text' : 'password'
            },

            getBody() {
                const { url, name, options } = _pick(this.form, ['url', 'name', 'options'])
                const { active } = _pick(this.webhook, ['active'])
                return {
                    url, name, options, active,
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .error-token-header {
        color:#F56C6C;
        font-size: 12px;
    }
</style>
