import _clone from 'lodash/clone'
import _mapValues from 'lodash/mapValues'

function collectServerError(errors) {
    return _mapValues(errors, '0')
}

export default {
    props: {
        save: {
            type: Function,
        },
    },

    data: () => ({
        form: {},
        show: false,
        processing: false,
        serverErrors: {},
    }),

    methods: {
        open(data = null) {
            if (data) {
                this.form = _clone(data)
            }

            this.show = true
        },

        close() {
            this.show = false
        },

        reset(elForm) {
            elForm.clearValidate()
            elForm.resetFields()
        },

        async submit(form, send) {
            this.serverErrors = {}
            const valid = form.model
                ? await form.validate()
                : true

            if (!valid) {
                return
            }

            try {
                this.processing = true
                await send(this.form)
                this.processing = false
                this.close()
            } catch (e) {
                this.processing = false
                throw e
            }
        },

        handleError(error) {
            if (error.response) {
                const data = error.response.data
                if (error.response.status === 422) {
                    this.serverErrors = collectServerError(data.errors)
                }
            } else {
                this.$message.error('Something went wrong. Please try again later.')
                throw error
            }
        },
    },
}
