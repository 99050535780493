<template>
    <div>
        <div class="d-flex flex-wrap justify-content-center pb-1">
            <DatePicker
                :show-button="false"
                :to="dateRange[1]"
                :from="dateRange[0]"
                class="filterDate"
            />

            <TechnicalTag
                :technical="false"
                class="filerTag d-flex align-items-center flex-grow-1"
                :placeholder="$t('myContent.search.tag')"
                @change="filter('tag', $event)"
            />

            <Search
                query-param="q"
                class="Search flex-grow-1"
                :placeholder="$t('myContent.search.search')"
            />
        </div>

        <el-alert
            v-if="errors"
            :title="errors.message"
            type="error"
            class="mb-1"
            show-icon
        >
            <ul>
                <li v-for="(error, index) in errors" :key="index">
                    {{ error[0] }}
                </li>
            </ul>
        </el-alert>

        <div>
            <el-table
                ref="filterTable"
                :data="socialShares"
                @filter-change="onFilter"
            >
                <el-table-column type="expand">
                    <ExpandItem slot-scope="props" class="expand-item" :social-share="props.row" />
                </el-table-column>

                <el-table-column
                    :show-overflow-tooltip="true"
                    :label="$t('myContent.socialShare.title')"
                    width="400"
                >
                    <template slot-scope="scope">
                        <shareable-title :shareable="scope.row.shareable" :type="scope.row.type" />
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('myContent.socialShare.creator')"
                    width="200"
                >
                    <template slot-scope="props">
                        <creator-avatar :creator="props.row.creator" />
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('myContent.socialShare.type')"
                    prop="type"
                    column-key="type"
                    class-name="text-capitalize"
                    :filters="entityTypes"
                    :filtered-value="activeType"
                    :filter-multiple="false"
                />

                <el-table-column
                    :label="$t('myContent.socialShare.rankScore')"
                    prop="rank_score"
                    width="150"
                />

                <el-table-column
                    :label="$t('myContent.socialShare.publishedAt')"
                    prop="published_at"
                    width="200"
                    :formatter="formatter"
                />

                <el-table-column
                    fixed="right"
                    :label="$t('myContent.socialShare.actions')"
                    width="90"
                >
                    <template slot-scope="scope">
                        <a
                            v-if="scope.row.status === 'published'"
                            :href="`https://www.facebook.com/${scope.row.social_post_id}`"
                            target="_blank"
                            class="link"
                        >
                            <el-button size="mini">
                                <i class="fa fa-facebook" />
                            </el-button>
                        </a>
                    </template>
                </el-table-column>
            </el-table>

            <pagination
                v-if="pagination"
                :current-page="pagination.current_page"
                :last-page="pagination.last_page"
                :page-count="pagination.total"
                :page-size="pagination.per_page"
            />
        </div>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import { mapGetters } from 'vuex'
    import { get } from '~/api/social-shares'
    import { toFacebook } from '~/utils/url'
    import { handlePageError } from '~/utils/pages'

    import ExpandItem from '~/components/social-shares/ExpandItem.vue'
    import Pagination from '~/components/Pagination.vue'
    import ShareableTitle from '~/components/social-shares/ShareableTitle.vue'
    import DatePicker from '~/components/widgets/DatePicker.vue'
    import Search from '~/components/searchPage/SearchInMyContentPage.vue'
    import TechnicalTag from '~/components/searchPage/TechnicalTag.vue'
    import { humanizeTime } from '~/filters/dates'
    import CreatorAvatar from '~/components/social-shares/CreatorAvatar.vue'
    import { loginWithSSR } from '~/utils/login'

    const entityTypes = [
        {
            text: 'Post',
            value: 'post',
        },
        {
            text: 'Question',
            value: 'question',
        },
        {
            text: 'Series',
            value: 'series',
        },
    ]

    export default {
        middleware: 'auth',

        components: {
            ExpandItem,
            Pagination,
            ShareableTitle,
            DatePicker,
            Search,
            TechnicalTag,
            CreatorAvatar,
        },

        data() {
            const tableActions = [
                {
                    icon: 'el-item-edit',
                    action: this.edit,
                },
            ]
            const from = this.$route.query.from
            const to = this.$route.query.to
            const dateRange = [from, to]
            return {
                tableActions,
                entityTypes,
                dateRange,
            }
        },

        computed: {
            ...mapGetters('auth', ['hasRole', 'authenticated', 'isSocialMediaEditor']),

            activeType() {
                return this.$route.query.type ? [this.$route.query.type] : undefined
            },
        },

        watchQuery: true,

        async asyncData({
            store, query, route, error, req, redirect,
        }) {
            try {
                const status = 'published'
                const type = route.query.type

                const data = await get({
                    status,
                    type,
                    limit: store.state.settings.perPage,
                    page: query.page || 1,
                    ...query,
                })

                return {
                    socialShares: data.data,
                    pagination: data.meta,
                    errors: null,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                if (e.response && e.response.status === 422) {
                    return { socialShares: [], pagination: [], errors: e.response.data.errors }
                }

                return handlePageError(error, e)
            }
        },

        methods: {
            formatter(row) {
                if (row.published_at) {
                    return `${humanizeTime(row.published_at)}`
                }

                return null
            },

            filterHandle(value, row, column) {
                const property = column.property

                return row[property] === value
            },

            onFilter(filter) {
                this.$router.push({

                    query: _assign({}, this.$route.query, {
                        status: 'published',
                        type: filter.type ? filter.type[0] : this.$route.query.type,
                        page: 1,
                    }),
                })
            },

            toFanpage(socialShare) {
                return toFacebook(socialShare.id)
            },

            filter(field, value) {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        [field]: value,
                        page: 1,
                    }),
                })
            },
        },

        meta: {
            title: ('myContent.title.sharesPublished'),
        },
    }
</script>
