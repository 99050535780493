<template>
    <el-table
        ref="filterTable"
        :data="posts"
        @filter-change="filter"
    >
        <el-table-column label="ID" prop="id" width="100" />
        <el-table-column
            :show-overflow-tooltip="true"
            :label="$t('reviewContents.postsSpam.title')"
            prop="title"
            min-width="350"
        />
        <el-table-column
            :label="$t('reviewContents.postsSpam.author')"
            width="200"
        >
            <template slot-scope="props">
                <creator-avatar :creator="props.row.user" />
            </template>
        </el-table-column>

        <el-table-column
            :formatter="formatter"
            :label="$t('reviewContents.postsSpam.published')"
            prop="published_at"
            width="200"
        />

        <el-table-column
            fixed="right"
            :label="$t('myContent.socialShare.actions')"
            width="120"
        >
            <template slot-scope="scope">
                <div>
                    <nuxt-link :to="toPost(scope.row)" target="_blank" class="link">
                        <el-button
                            v-tooltip
                            size="mini"
                            :title="$t('postBody.postContent.notSpam.tooltipLink')"
                        >
                            <i class="fa fa-external-link" />
                        </el-button>
                    </nuxt-link>
                    <el-button
                        v-tooltip
                        :title="$t('postBody.postContent.notSpam.tooltipNotSpam')"
                        size="mini"
                        type="primary"
                        @click="confirmNotSpam(scope.row)"
                    >
                        <i class="el-icon-check" />
                    </el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    import _assign from 'lodash/assign'
    import _findIndex from 'lodash/findIndex'
    import { post as toPost } from '~/utils/url'
    import { humanizeTime } from '~/filters/dates'
    import CreatorAvatar from '~/components/social-shares/CreatorAvatar.vue'
    import { reportNotSpam } from '~/api/posts'

    export default {
        components: {
            CreatorAvatar,
        },

        props: {
            posts: Array,
        },

        methods: {
            formatter(row) {
                return `${humanizeTime(row.published_at)}`
            },

            filter(field, value) {
                this.$router.push({
                    query: _assign({}, this.$route.query, {
                        [field]: value,
                        page: 1,
                    }),
                })
            },

            confirmNotSpam(row) {
                this.$confirm(this.$t('postBody.postContent.notSpam.content'), this.$t('postBody.postContent.notSpam.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => this.reportNotSpam(row))
            },

            async reportNotSpam(row) {
                try {
                    await reportNotSpam(row.slug)
                    this.$message.success(this.$t('postBody.postContent.notSpam.success'))
                    const index = _findIndex(this.posts, { id: row.id })
                    this.posts.splice(index, 1)
                } catch (error) {
                    this.$message.error(this.$t('postBody.postContent.notSpam.error'))
                }
            },

            toPost(row) {
                return toPost(row)
            },
        },
    }
</script>
