// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="container px-0">
        <div class="d-flex py-3">
            <div class="col-lg-3 pl-0 hidden-md-down">
                <slot name="menu" />
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="card user-setting-group pb-4 border-0">
                    <div class="section-title-line mb-1">
                        <h2>
                            <el-button
                                type="text"
                                class="p-0 mr-05 hidden-lg-up"
                                style="font-size:1.375rem;"
                                icon="el-icon-s-unfold text-body"
                                @click="showDrawer = true"
                            />
                            <span>{{ $t(pageTitle) }}</span>
                        </h2>
                        <hr class="filler-line">
                    </div>

                    <transition name="slide">
                        <nuxt-child />
                    </transition>
                </div>
            </div>

            <el-drawer
                :with-header="false"
                :visible.sync="showDrawer"
                class="content-menu-drawer hidden-lg-up"
                direction="ltr"
                size="240px"
            >
                <slot name="menu" />
            </el-drawer>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            pageTitle: String,
        },

        data() {
            return {
                showDrawer: false,
            }
        },

        watch: {
            $route() {
                this.showDrawer = false
            },
        },
    }
</script>
