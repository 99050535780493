<template>
    <el-menu
        :default-active="$route.path"
        class="v-float-menu"
        @select="onMenuItemClick"
    >
        <el-submenu v-if="isSocialMediaEditor || isAdmin" index="/support-manager/social-shares" keep-query="true">
            <template slot="title">
                <i class="fa fa-share fa-fw" />
                <span slot="title" class="ml-05">
                    {{ $t('contentMenu.socialShare.title') }}
                    ({{ stats.social_share_count.data.socialshare_count || 0 }})
                </span>
            </template>

            <el-menu-item index="/review-contents/social-shares/drafts">
                <i class="fa fa-lock fa-fw" />
                <span class="ml-05">
                    {{ $t('contentMenu.socialShare.drafts') }}
                    ({{ stats.social_share_count.data.socialshare_draft_count || 0 }})
                </span>
            </el-menu-item>

            <el-menu-item index="/review-contents/social-shares/scheduled">
                <i class="fa fa-unlock-alt fa-fw" />
                <span class="ml-05">
                    {{ $t('contentMenu.socialShare.scheduled') }}
                    ({{ stats.social_share_count.data.socialshare_scheduled_count || 0 }})
                </span>
            </el-menu-item>

            <el-menu-item index="/review-contents/social-shares/published">
                <i class="fa fa-globe fa-fw" />
                <span class="ml-05">
                    {{ $t('contentMenu.socialShare.published') }}
                    ({{ stats.social_share_count.data.socialshare_published_count || 0 }})
                </span>
            </el-menu-item>
        </el-submenu>

        <el-menu-item v-if="permissions.canModeration" index="/review-contents/posts-spam" keep-query="true">
            <i class="fa fa-list fa-fw" />
            <span slot="title" class="ml-05">
                {{ $t('contentMenu.postsSpam') }} ({{ stats.post_spam_count.data.post_spam_count || 0 }})
            </span>
        </el-menu-item>
    </el-menu>
</template>

<script>
    import _get from 'lodash/get'
    import { mapGetters } from 'vuex'

    export default {
        props: {
            stats: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapGetters('auth', ['hasRole', 'isSocialMediaEditor', 'isAdmin', 'isCollaborator', 'isEditor', 'isSpamReviewer']),

            permissions() {
                const isAdmin = this.isAdmin
                const isCollaborator = this.hasRole('collaborator')
                const isEditor = this.hasRole('editor')
                const isSpamReviewer = this.isSpamReviewer

                return {
                    canModeration: isCollaborator || isAdmin || isEditor || isSpamReviewer,
                }
            },
        },

        methods: {
            onMenuItemClick(path, keyPath, item) {
                const keepQuery = _get(item, '$attrs.keep-query') || _get(item, '$parent.$attrs.keep-query')
                let query = {}

                if (keepQuery) {
                    query = this.$route.query
                    query.page = 1
                }

                this.$router.push({
                    path,
                    query,
                }).catch(() => {})
            },
        },
    }
</script>

<style lang="scss">
    .v-float-menu {
        max-height: 100vh;
        overflow: auto;
    }
</style>
