// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="service-settings">
        <SettingsItem>
            <template slot="title">
                {{ $t('mainNavbar.setting.appearances.postLayout.title') }}
            </template>
            <template slot="description">
                <!-- eslint-disable-next-line-->
                {{ $t('mainNavbar.setting.appearances.postLayout.detail') }}
            </template>

            <el-radio-group
                slot="button"
                v-model="settings.preferred_post_layout"
                size="medium"
                @change="updateSettings('preferred_post_layout', $event)"
            >
                <el-radio-button label="simple">
                    {{ $t('mainNavbar.setting.appearances.postLayout.simple') }}
                </el-radio-button>
                <el-radio-button label="preview">
                    {{ $t('mainNavbar.setting.appearances.postLayout.preview') }}
                </el-radio-button>
            </el-radio-group>
        </SettingsItem>

        <div class="service-settings--layout">
            <SettingsItem v-if="userHaveSubdomain">
                <template slot="title">
                    {{ $t('mainNavbar.setting.appearances.layout.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.appearances.layout.detail') }}
                </template>

                <el-radio-group
                    slot="button"
                    v-model="checkedLayout"
                    size="medium"
                    @change="updateLayout(user.username, $event)"
                >
                    <el-radio-button label="default">
                        {{ $t('mainNavbar.setting.appearances.layout.options.default') }}
                    </el-radio-button>
                    <el-radio-button label="layout-subdomains-2">
                        {{ $t('mainNavbar.setting.appearances.layout.options.layoutSubdomain2') }}
                    </el-radio-button>
                </el-radio-group>
            </SettingsItem>
            <div class="service-settings--layout---preview">
                <img
                    src="~assets/images/layout1-profile.png"
                    :class="{'active': checkedLayout === 'default'}"
                    @click="() => {
                        checkedLayout = 'default'
                        updateLayout(user.username, checkedLayout)
                    }"
                >
                <img
                    src="~assets/images/layout2-profile.png"
                    :class="{'active': checkedLayout === 'layout-subdomains-2'}"
                    @click="() => {
                        checkedLayout = 'layout-subdomains-2'
                        updateLayout(user.username, checkedLayout)
                    }"
                >
            </div>
        </div>

        <SettingsItem>
            <template slot="title">
                {{ $t('mainNavbar.setting.appearances.itemsPerPage.title') }}
            </template>
            <template slot="description">
                <!-- eslint-disable-next-line -->
                {{ $t('mainNavbar.setting.appearances.itemsPerPage.detail') }}
            </template>

            <el-radio-group
                slot="button"
                v-model="settings.post_per_page"
                size="medium"
                @change="updateSettings('post_per_page', $event)"
            >
                <el-radio-button label="20" />
                <el-radio-button label="40" />
                <el-radio-button label="60" />
            </el-radio-group>
        </SettingsItem>

        <SettingsItem>
            <template slot="title">
                {{ $t('mainNavbar.setting.appearances.highlightThemes.title') }}
            </template>
            <template slot="description">
                {{ $t('mainNavbar.setting.appearances.highlightThemes.detail') }}
            </template>

            <el-radio-group
                slot="button"
                v-model="settings.highlight_theme"
                size="medium"
                @change="updateSettings('highlight_theme', $event)"
            >
                <el-radio-button label="dark">
                    {{ $t('mainNavbar.setting.appearances.highlightThemes.dark') }}
                </el-radio-button>
                <el-radio-button label="default">
                    {{ $t('mainNavbar.setting.appearances.highlightThemes.light') }}
                </el-radio-button>
            </el-radio-group>
        </SettingsItem>

        <SettingsItem>
            <template slot="title">
                {{ $t('mainNavbar.setting.appearances.homePage.title') }}
            </template>
            <template slot="description">
                {{ $t('mainNavbar.setting.appearances.homePage.detail') }}
            </template>

            <el-radio-group
                slot="button"
                v-model="settings.homepage"
                size="medium"
                @change="updateSettings('homepage', $event)"
            >
                <el-radio-button label="default">
                    {{ $t('mainNavbar.setting.appearances.homePage.followings') }}
                </el-radio-button>
                <el-radio-button label="newest">
                    {{ $t('mainNavbar.setting.appearances.homePage.newest') }}
                </el-radio-button>
            </el-radio-group>
        </SettingsItem>

        <SettingsItem>
            <template slot="title">
                {{ $t('mainNavbar.setting.appearances.locale.title') }}
            </template>
            <template slot="description">
                {{ $t('mainNavbar.setting.appearances.locale.detail') }}
            </template>

            <LanguageSwitcher slot="button" />
        </SettingsItem>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { getServiceSettings, updateSettings } from 'viblo-sdk/api/me'
    import { updateLayout } from '~/api/users'
    import { displayError } from '~/utils/pages'
    import SettingsItem from '~/components/settings/SettingsItem.vue'
    import LanguageSwitcher from '~/components/footer/LanguageSwitcher.vue'

    export default {
        components: {
            SettingsItem,
            LanguageSwitcher,
        },

        data() {
            return {
                checkedLayout: this.$store.state.auth.user?.layout,
            }
        },

        computed: {
            ...mapState('auth', ['user']),

            userHaveSubdomain() {
                return this.user.subdomain !== null
            },
        },

        async asyncData() {
            const { data: settings } = await getServiceSettings()

            return { settings }
        },

        methods: {
            updateSettings(name, value) {
                return updateSettings(name, value)
                    .then(() => {
                        this.$store.dispatch('settings/setValue', { name, value })
                        this.$message.success(this.$t('mainNavbar.setting.appearances.success'))
                    })
                    .catch(displayError(this.$message.error))
            },

            async updateLayout(username, value) {
                try {
                    await updateLayout(username, {
                        layout: value,
                    })
                    this.$store.commit('auth/updateLayout', value)
                    this.$message.success(this.$t('mainNavbar.setting.appearances.success'))
                } catch (error) {
                    displayError(this.$message.error)
                }
            },
        },

        // the page title will be parse from `meta` options by `~/components/mixins/pageTitle`
        meta: {
            title: 'mainNavbar.setting.appearances.title',
        },
    }
</script>

<style lang="scss">
.service-settings {
    &--layout {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #dbe3e8;

        .settings-item {
            margin-bottom: 8px;
            padding-bottom: 0;
            border: none;

            @media only screen and (max-width: 765px) {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .settings-item__button {
                    align-self: flex-start;
                }
            }
        }

        .el-radio-button, .el-radio-button__inner  {
            width: 170px;

            @media only screen and (max-width: 420px) {
                width: 130px;
            }
        }

        &---preview {
            display: flex;
            justify-content: flex-end;
            gap: 8px;

            @media only screen and (max-width: 765px) {
                justify-content: flex-start;
            }

            img {
                max-width: 170px;
                max-height: 173px;
                cursor: pointer;

                &.active {
                    border: 1px solid #409EFE;
                    border-radius: 12px;
                }

                @media only screen and (max-width: 420px) {
                    max-width: 130px;
                    max-height: 130px;
                }
            }
        }
    }
}
</style>
