// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="notification-settings">
        <div class="settings-group">
            <h2 class="settings-group__header">
                {{ $t('mainNavbar.setting.notifications.content.title') }}
            </h2>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.content.contentPeople.content') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.content.contentPeople.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_following_notifications"
                    @change="toggleNotification('receive_following_notifications')"
                />
            </SettingsItem>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.content.question.content') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.content.question.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_questions_notification"
                    @change="toggleNotification('receive_questions_notification')"
                />
            </SettingsItem>
        </div>

        <div class="settings-group">
            <h2 class="settings-group__header">
                {{ $t('mainNavbar.setting.notifications.comment.title') }}
            </h2>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.comment.comments.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.comment.comments.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_comments_notification"
                    @change="toggleNotification('receive_comments_notification')"
                />
            </SettingsItem>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.comment.mentions.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.comment.mentions.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_mention_notification"
                    @change="toggleNotification('receive_mention_notification')"
                />
            </SettingsItem>
        </div>

        <div class="settings-group">
            <h2 class="settings-group__header">
                {{ $t('mainNavbar.setting.notifications.reputations.title') }}
            </h2>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.reputations.voteAndclip.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.reputations.voteAndclip.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_upvotes_and_clips_notification"
                    @change="toggleNotification('receive_upvotes_and_clips_notification')"
                />
            </SettingsItem>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.reputations.acceptedAnswers.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.reputations.acceptedAnswers.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_answer_accepted_notification"
                    @change="toggleNotification('receive_answer_accepted_notification')"
                />
            </SettingsItem>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.reputations.newFollowers.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.reputations.newFollowers.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_new_followers_notification"
                    @change="toggleNotification('receive_new_followers_notification')"
                />
            </SettingsItem>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.reputations.vibloFanpage.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.reputations.vibloFanpage.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_post_shared_notification"
                    @change="toggleNotification('receive_post_shared_notification')"
                />
            </SettingsItem>
        </div>

        <div>
            <h2 class="settings-group__header">
                {{ $t('mainNavbar.setting.notifications.email.title') }}
            </h2>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.email.unread.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.email.unread.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_email_notification"
                    @change="toggleNotification('receive_email_notification')"
                />
            </SettingsItem>

            <SettingsItem>
                <template slot="title">
                    {{ $t('mainNavbar.setting.notifications.email.newsletter.title') }}
                </template>
                <template slot="description">
                    {{ $t('mainNavbar.setting.notifications.email.newsletter.detail') }}
                </template>
                <el-switch
                    slot="button"
                    :value="settings.receive_newsletter"
                    @change="toggleNotification('receive_newsletter')"
                />
            </SettingsItem>
        </div>
    </div>
</template>

<script>
    import { getNotificationSettings, updateSettings } from 'viblo-sdk/api/me'
    import SettingsItem from '~/components/settings/SettingsItem.vue'

    export default {
        components: {
            SettingsItem,
        },

        async asyncData() {
            const { data: settings } = await getNotificationSettings()

            return { settings }
        },

        methods: {
            toggleNotification(name) {
                const current = this.settings[name]
                const next = !current

                return updateSettings(name, next)
                    .then(() => {
                        this.$message.success(this.$t('notification.settingsUpdated'))
                        this.settings[name] = next
                    })
                    .catch(() => {
                        this.$message.error(this.$t('settings.error'))
                    })
            },
        },

        // the page title will be parse from `meta` options by `~/components/mixins/pageTitle`
        meta: {
            title: 'mainNavbar.setting.notifications.title',
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .notification-settings {
        .settings-group {
            margin-bottom: 3rem;

            &__header {
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid $gray-lighter;
            }
        }
    }
</style>
