// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div
        v-if="show && !isOneTapDisplayed &&!showPopupMid"
        class="fixed-bottom-bar"
        :class="[!showPopupMid && isColor ? 'whiteBackground' : '', isDetailPostPage ? 'fixedBottombar' : '' ]"
    >
        <div class="wrapper d-flex">
            <div class="logo-bottom">
                <img v-if="!showPopupMid && isColor" src="/favicon-128.png" alt="Viblo">
                <img v-else src="/favicon-white-128.png" alt="Viblo">
            </div>
            <div class="content d-flex flex-column">
                <div class="text">
                    <span class="text-register">
                        {{ $t('dialogRegister.title') }}
                    </span>
                </div>
                <div class="button d-flex mt-1">
                    <button class="login-button" @click="showAuthDialog">
                        {{ $t('dialogRegister.login') }}
                    </button>
                    <a :href="showRegister" class="register-button">
                        {{ $t('dialogRegister.register') }}
                    </a>
                </div>
            </div>
            <div class="icon-close" style="cursor:pointer" @click="close()">
                <i class="icon fa fa-close" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        data: () => ({ isColor: false }),

        computed: {
            ...mapState('auth', { show: 'showLetRegister', showPopupMid: 'reminderLogin' }),
            ...mapState('oneTap', { isOneTapDisplayed: 'isDisplayed' }),

            showRegister() {
                return `${process.env.SSO_APP_URL}/register`
            },

            isDetailPostPage() {
                if (this.$route.name === 'detail-post') {
                    return true
                }
                return false
            },
        },

        watch: {
            showPopupMid(data) {
                if (data === false) {
                    this.isColor = true
                }
            },

            $route() {
                this.isColor = false
            },
        },

        methods: {
            close() {
                this.$store.commit('auth/setShow', false)
            },
            ...mapActions('auth', ['showAuthDialog']),
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/fonts";
    @import "~assets/sass/bootstrap/borders";

    .fixed-bottom-bar {
        position: fixed;
        height: 140px;
        width: 100%;
        bottom: 0px;
        z-index: 80;
        background-color: #39609e;
        padding: 0.5rem 0;
        -webkit-box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, .15);
        -moz-box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, .15);
        box-shadow: 0 -1px 5px 1px rgba(0, 0, 0, .15);

        .wrapper {
            display: flex;
            align-items: center;
            margin: 0 auto;
            height: 100%;

            .logo-bottom {
                width: 100px;
                height: 95px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .icon {
                color: white;
                font-size: x-large;
            }
        }
    }

    .whiteBackground{
        background-color: #ffffff;
        bottom: 0px;

        .login-button {
            border: none;
            background-color: #1877F2;
            font-weight: 600;
            font-size: 17px;
            border-color: #ffffff;
            color: white;
        }

        .login-button:hover {
            background-color: #4c95f3;
            color: white;
            cursor: pointer;
            text-decoration: none;
        }

        .text-register {
            font-size: 1.25rem;
            color: #050505;
        }

        .wrapper {
            .icon {
                color: #1877F2;
                font-size: x-large;
            }
        }

        @media only screen and (max-width: 700px){
            .text-register {
                font-size: 1rem;
            }
        }
    }

    .text-register {
        font-size: 1.25rem;
        color: white;
    }

    .button {
        justify-content: space-around;
    }

    .register-button {
        text-align: center;
        max-width:200px;
        color: white;
        background-color: #42b72a;
        font-weight: 600;
        font-size: 17px !important;
    }

    .login-button {
        background-color: #1877F2;
        background: white;
        color: black;
        border: none;
        padding: 0px 40px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 17px;
    }

    .register-button:hover {
        background-color: #239B56;
        color: white;
        cursor: pointer;
        text-decoration: none;
    }

    .login-button:hover {
        background-color: #faebd7;
        color: black;
        cursor: pointer;
        text-decoration: none;
    }

    .theme-dark {
        .fixed-bottom-bar {
            background-color: $dark-bg;
        }

        .text-register {
            color: white;
        }

        .whiteBackground{
            background-color: $dark-bg;

            .login-button {
                background-color: #1877F2;
                border-color: #ffffff;
                color: white;
            }

            .login-button:hover {
                background-color: #4c95f3;
                color: white;
            }

            .text-register {
                color: #fff;
            }

            .wrapper {
                .icon {
                    color: #fff;
                }
            }
        }
    }

    @media only screen and (min-width: 980px) {
        .wrapper {
            width: 1000px;
        }

        .logo-bottom {
            display: block;
            margin-left: 85px;
        }

        .content {
            margin-left: 35px;
        }

        .register-button {
            padding: .5rem 3rem;
            border-radius: 7px;
            font-size: 20px;
        }

        .text-register {
        font-size: 1.25rem;
        }

        .icon-close {
            height: 72%;
            margin-left: 85px;
        }

    }

    @media only screen and (max-width: 980px){
        .wrapper {
            width: 100%;
        }

        .logo-bottom {
            margin-left: 40px;
        }

        .content {
            margin-left: 35px;
        }

        .register-button {
            padding: .5rem 3rem;
            border-radius: 7px;
            font-size: 20px;
        }

        .text-register {
            font-size: 1.25rem;
        }

        .icon-close {
            height: 72%;
            margin: 0px 40px;
        }
    }

    @media only screen and (max-width: 700px){
        .wrapper {
            justify-content: center;
        }

        .logo-bottom {
            display: none
        }

        .register-button {
            padding: .2rem 1.5rem;
            border-radius: 5px;
            font-size: 15px;
        }

        .text-register {
            font-size: 1rem;
        }

        .icon-close {
            height: 57%;
            margin: 0px 20px;
        }
    }

    @media only screen and (max-width: 345px){
        .wrapper {
            justify-content: center;
        }

        .logo {
            display: none
        }

        .register-button {
            padding: .2rem 1.5rem;
            border-radius: 5px;
            font-size: 15px;
        }

        .text-register {
            font-size: 1rem;
        }

        .icon-close {
            height: 78%;
            margin: 0px 10px;
        }

        .content {
            margin-left: 20px;
        }
    }

    @media screen and (max-width: 370px) {
        .login-button {
            max-width: 120px;
            font-size: 16px !important;
            padding: 0 20px;
        }
        .egister-button {
            font-size: 16px !important;
        }
    }
</style>
