<template>
    <div>
        <div>
            <div class="d-flex justify-content-between">
                <span class="text-bold">Message:</span>
                <div class="text-muted">
                    {{ $t('myContent.socialShare.createdAt') }} {{ socialShare.created_at | humanizeTime }}
                </div>
            </div>
            <p>{{ socialShare.message }}</p>
        </div>
        <div v-if="socialShare.status === 'published'">
            <div>
                <span class="text-bold">
                    {{ $t('myContent.socialShare.publishedAt') }}
                </span>
                {{ socialShare.published_at | humanizeTime }}
            </div>
        </div>
        <div v-if="socialShare.status === 'scheduled'">
            <span class="text-bold">{{ $t('myContent.socialShare.publishAt') }} </span>
            {{ socialShare.scheduled_publish_time | humanizeTime }}
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            socialShare: {
                type: Object,
                required: true,
            },
        },
    }
</script>
