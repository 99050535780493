<template>
    <SettingLayout :page-title="pageTitle">
        <Menu slot="menu" :stats="stats" />
    </SettingLayout>
</template>

<script>
    import { getStats } from '~/api/users'
    import SettingLayout from '~/components/settings/Layout.vue'
    import Menu from '~/components/auth/MenuReviewContents.vue'
    import pageTitle from '~/components/mixins/pageTitle'
    import { loginWithSSR } from '~/utils/login'
    import { handlePageError } from '~/utils/pages'

    export default {
        middleware: 'auth',

        scrollToTop: true,

        components: {
            SettingLayout,
            Menu,
        },

        mixins: [
            pageTitle({ defaultTitle: 'Activity Log' }),
        ],


        async asyncData({
            store, req, redirect, error,
        }) {
            try {
                const data = await getStats()

                return {
                    stats: data.data,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },
    }
</script>
