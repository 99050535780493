// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const getWebhook = params => axios.get('/webhooks', { params })

export const updateWebhook = data => axios.post('/webhooks', data)

export const getPayloadStructure = name => axios.get('/webhooks/payload-structure', name)

export const toggleActiveWebhook = id => axios.put(`/webhooks/toggle-active/${id}`)

export const testWebhook = id => axios.post(`/webhooks/test-webhook/${id}`)

export const updateAndTestWebhook = (id, data) => axios.post(`/webhooks/update-and-test-webhook/${id}`, data)
